import {
  AppConfig,
  DocumentAnalysisModelEnum,
  DocumentTypeEnum,
  Environment,
  State,
} from 'shared-domain';

import { environment } from '../environments/environment';
import { companyFields } from './company';
import { affiliatedCompanyFields } from './affiliated-company';
import { individualFields } from './individual';
import { stepsConfig } from './step';

export const bootstrapConfig: Partial<Omit<AppConfig, 'environment'>> & {
  environment: Environment;
} = {
  websiteTitle: 'Roundtable',
  languages: ['en'],
  queryParamsRequired: ['email', 'externalId'],
  supportLink: 'mailto:contact@roundtable.eu',
  hasKyb: false,
  companyFields,
  affiliatedCompanyFields,
  individualFields,
  stepsConfig,
  environment,
  documentAnalysisConfig: {
    [`document_type:${DocumentTypeEnum.proof_of_address}`]: (store: State) => ({
      automatic_approval: false,
      automatic_rejection: false,
      parameters: {
        model: DocumentAnalysisModelEnum.proof_of_address,
        max_age_in_days: 90,
      },
    }),
  },
};
